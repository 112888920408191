import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBlock from "../components/contentBlock"

class NotFoundPage extends React.Component {
  render() {
    // const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title

    // TODO not works during SSR
    // TODO try html meta redirect
    // location.replace('/');

    return (
      <Layout location={this.props.location}>
        <SEO title="Página no existe" />
        <ContentBlock>
          <h1><span role="img" aria-label="emoji"> 🤷‍♂️  </span> Página no existe</h1>
          <Link to="/">Ir al inicio</Link>
        </ContentBlock>
      </Layout>
    )
  }
}

export default NotFoundPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `
